import React from 'react'
import { P, SH1, SH2 } from '../components/titles'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'



const Roadmap = () => {
    return (
        <StyledCol lg={6} xs={12} id="roadmap">
            <SH1 style={{ textAlign: 'left', padding: 30 }}>
                ROADMAP
            </SH1>
            <Row>
                <LeftCol lg={6} xs={12}>
                    {roadmap.left.map((item,index) => <RoadmapDiv>
                        <SH2>{item.title}</SH2>
                        <P>
                            {item.text}

                        </P>
                        {item.link && <a style={{fontSize: 18}} target={'_blank'} href={item.link.link}>🐈 {item.link.text}</a>}
                        <ForDesktop>
                        {index === 0 ? <><br/><br/><br/></> : ''}
                        </ForDesktop>
                        
                    </RoadmapDiv> )}
                </LeftCol>

                <RightCol lg={6} xs={12}>
                {roadmap.right.map(item => <RoadmapDiv>
                        <SH2>{item.title}</SH2>
                        <P>
                            {item.text}
                        </P>
                    </RoadmapDiv> )}
                </RightCol>
            </Row>
        </StyledCol>
    )
}

const RoadmapDiv = styled.div`
text-align: left;
padding: 30px;
padding-top: 0px;
`
const LeftCol = styled(Col)`
@media (min-width: 585px) {
    border-right: 2px solid black;
  }
`
const RightCol = styled(Col)`
@media (max-width: 991px) {
    border-bottom: 2px solid black;
  }
`
const StyledCol = styled(Col)`
@media (min-width: 585px) {
    border-right: 2px solid black;
}
`

const ForDesktop = styled.div`
@media (max-width: 585px) {
    display: none;
}
`

const roadmap = {
    left :[{
        title: 'Episode 1 : Cat Frens ✔️',
        text: `Cat Frens sold out Frens. TBH, Frens can't wait to launch Pet Frens.`,
        link: {
            link: 'http://catfrens.com',
            text: 'Cat Frens'
        },
    },
    {
        title: 'Episode 2 : Pet Frens 🚨',
        text: `Pets are sweet and waiting to meet with their Frens. Utilities are cooking for the holders. `
    }],
    right: [{
        title: 'Episode 3 : Token Launch - NFT Staking Program ⌛️',
        text: `Frens will be able to stake their NFTs to earn rewards. Details will be announced in 2022-Q2. `
    },
    {
        title: 'Episode 4 : NFT Dashboard - Defi Launch 🚀',
        text: `The scope will be discussed with Frens during the previous episodes.`
    }]
    
}

export default Roadmap