import React from 'react'
import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import {SH1} from '../components/titles'
import adnan from '../assets/566.png'
import cag from '../assets/cag.png'
import lily from '../assets/lily.png'
import orcun from '../assets/orcun.png'
import { FaTwitter } from 'react-icons/fa'

const Team = () => {
  return (
    <Container id='team'>
    <Row style={{ padding: 30 }}>

      <SH1>
        TEAM
      </SH1>
      <p style={{ fontSize: 18 }}>
        We Frens come together from diverse professional backgrounds, including design, game development, growth, and blockchain. We are committed to delivering community-driven products and services that drastically lower the barrier to getting experience on web3 and NFT Spaces.
      </p>
      <StyledWrapper>
        {team.map(member => <StyledTeam>
          <img style={{ border: '2px solid black' }} src={member.image} width={200} />
          <StyledDiv>
            <div>{member.title}</div>
            <a target={'_blank'} href={member.link}>
              <FaTwitter /> {member.username}
            </a>
          </StyledDiv>

        </StyledTeam>)}
      </StyledWrapper>

    </Row>
  </Container>
  )
}


const StyledTeam = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 200px;
margin: 15px;
`
const StyledDiv = styled.div`
border: 2px solid black;
background-color: #fce26f;
width: 200px;
margin-top: 5px;
`
const StyledWrapper = styled.div`
display: flex; 
justify-content: center;
align-items: center;
flex-direction: row;

@media (max-width: 540px) {
    flex-direction: column;
  }`
const team = [
    {
      title: 'Dev',
      link: 'https://twitter.com/cagbsc',
      image: cag,
      username: '@cagbsc'
    },
    {
      title: 'Growth & Marketing',
      link: 'https://twitter.com/squad566',
      image: adnan,
      username: '@566.bsc'
    },
    {
      title: 'Art & Design',
      link: 'https://twitter.com/sayinorcun',
      image: orcun,
      username: '@sayinorcun'
    },
    {
      title: 'Partnerships',
      link: 'https://twitter.com/m_taylor_r',
      image: lily,
      username: '@lily_taylor'
    },
  ]


export default Team