import React,{useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { SH2 } from './components/titles';
import { useMoralis, useNativeBalance,useMoralisWeb3Api } from "react-moralis";

// import {traits} from '../public/assets/traits/'
// import tet from './assets/traits/bg/Beige#5.png'
const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}
// Note from the docs -> Warning: The arguments passed to require.context must be literals!
importAll(require.context("./assets/traits/bg", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/back", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/bird_body", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/bunny_body", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/dog_body", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/monkey_body", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/dress", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/hat", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/eye", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/monkey_ironman", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/bird_ironman", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/dog_ironman", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/bunny_ironman", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/bird_mouth", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/bunny_mouth", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/dog_mouth", false, /\.(png|jpe?g|svg)$/));
importAll(require.context("./assets/traits/monkey_mouth", false, /\.(png|jpe?g|svg)$/));

const bgs = Object.entries(cache).map(module => module);


const Traits = () => {


    return (
        <div>
            <Container>
                <Row>
                    <SH2>Backgrounds</SH2>
                    {bgs.map((item, index) => {
                        let iii = item[0].indexOf('#')
                        if (iii > -1) {
                            item[0] = item[0].substring(0, iii)
                        }
                        if (index === 28) {
                            return <>
                                <SH2>Backs</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        if (index === 34) {
                            return <>
                                <SH2>Bodies</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        if (index === 65) {
                            return <>
                                <SH2>Dresses</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        if (index === 101) {
                            return <>
                                <SH2>Hats</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        if (index === 121) {
                            return <>
                                <SH2>Eyes</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        if (index === 133) {
                            return <>
                                <SH2>Ironmans</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        if (index === 137) {
                            return <>
                                <SH2>Mouths</SH2>
                                <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                                    <p>{item[0].replace('.png', '').replace('./', '')}</p>
                                </Col></>

                        }
                        return <Col><img style={{ margin: 5, border: '2px solid black' }} width={100} src={item[1].default} />

                            <p>{item[0].replace('.png', '').replace('./', '')}</p>
                        </Col>

                    })}


                </Row>


            </Container>
        </div>
    )
}

export default Traits