import React, { useState,useEffect } from "react";
import { useMoralis, useNFTBalances, useNativeBalance } from "react-moralis";
import { Card, Image, Tooltip, Input, Skeleton } from "antd";
import { Button, Modal } from "react-bootstrap";
import {
    FileSearchOutlined,
    SendOutlined,
    ShoppingCartOutlined,
} from "@ant-design/icons";
import { getExplorer } from "../../helpers/networks";
import AddressInput from "../AddressInput/AddressInput";
import { useVerifyMetadata } from "../../hooks/useVerifyMetadata";
// import { traits } from "../../traits";
import styled from 'styled-components'
import { useMoralisWeb3Api } from "react-moralis";

const { Meta } = Card;

export const TeamCard = styled.div`
    background-color: #FCE26F;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: #030303;
    border-radius: 12px 12px 12px 12px;
    margin: 8px 8px 8px 8px;
    border-radius: 3px;
    /* --e-column-margin-right: 8px; */
    /* --e-column-margin-left: 8px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    padding-bottom: 10px;
`

const styles = {
    NFTs: {
        display: "flex",
        flexWrap: "wrap",
        // WebkitBoxPack: "start",
        justifyContent: "center",
        margin: "0 auto",
        // maxWidth: "1000px",
        width: "100%",
        gap: "10px",
    },
};

function NFTBalance() {
    const { data: NFTBalances } = useNFTBalances();
    const { Moralis, chainId } = useMoralis();
    const [visible, setVisibility] = useState(false);
    const [receiverToSend, setReceiver] = useState(null);
    const [amountToSend, setAmount] = useState(null);
    const [nftToSend, setNftToSend] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { verifyMetadata } = useVerifyMetadata();

    // console.log(NativeBalance)
    const Web3Api = useMoralisWeb3Api();

// const getLowest = async () => {
 
// const options = { address: "0x28472a58A490c5e09A238847F66A68a47cC76f0f", days: "1"};
//   const result = await Moralis.Web3API.token.getNFTLowestPrice(options);
//   console.log('RES',result);
// };
// getLowest()

// const fetchNFTOwners = async () => {
//     const options = {
//       address: "0x56B960A424189C9bb7fAB5c18Ef4a020994e97F8",
//       chain: "bsc",
//     };
//     const nftOwners = await Web3Api.token.getNFTOwners(options);
//     console.log(nftOwners);
//   };
//   const fetchNFTTrades = async () => {
//     const options = {
//       address: "0x56B960A424189C9bb7fAB5c18Ef4a020994e97F8",
//       limit: "10",
//       chain: "bsc",
//     };
//     const NFTTrades = await Web3Api.token.getNFTTrades(options);
//     debugger
//     console.log('awdawdawdwad',NFTTrades);
//   };
// useEffect(() => {
//     fetchNFTOwners()
//     fetchNFTTrades()
// }, [])

    async function transfer(nft, amount, receiver) {
        const options = {
            type: nft?.contract_type?.toLowerCase(),
            tokenId: nft?.token_id,
            receiver,
            contractAddress: nft?.token_address,
        };

        if (options.type === "erc1155") {
            options.amount = amount ?? nft.amount;
        }

        setIsPending(true);

        try {
            const tx = await Moralis.transfer(options);
            setIsPending(false);
        } catch (e) {
            alert(e.message);
            setIsPending(false);
        }
    }




    const handleTransferClick = (nft) => {
        setNftToSend(nft);
        setVisibility(true);
    };

    const handleChange = (e) => {
        setAmount(e.target.value);
    };
    // const traitValueHandler =(trait_type)=> {
    //     // const test = 
    //     return traits.find(t => t.trait === trait_type).occurrence
    // }
    

    return (
        <div style={{ padding: "15px", width: "100%" }}>
            <h3 style={{ textAlign: 'center' }}>My Pets</h3>

            <div style={styles.NFTs}>
                <Skeleton loading={!NFTBalances?.result}>
                    {NFTBalances?.result &&
                        NFTBalances?.result?.filter(nft => nft.name === 'Pet Frens').map((nft, index) => {
                            const attributes = nft?.metadata && nft.metadata.attributes
                            const name = nft?.metadata && nft.metadata.name
                            nft = verifyMetadata(nft);
                            console.log(nft)
                            return (
                                <TeamCard>
                                    <img style={{ marginTop: 15, borderRadius: 5, marginBottom: 5 }} width={250} src={nft?.image || 'error'} />
                                    <div style={{ backgroundColor: '#fff4da', borderRadius: 4, width: 250, textAlign: 'center', minHeight: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20 }}>
                                        {name || nft.name}
                                    </div>
                                    {/* <span style={{ fontSize: 6 }}>{nft.token_address}</span> */}
                                   {nft?.metadata?.attributes.map(att => <div style={{display: 'flex',justifyContent: 'space-between', width: '100%', paddingLeft: 25, paddingRight: 25}}><div>{att.trait_type}</div> <div>{att.value}</div> </div>)}
                                    {
                                        // name ? <div style={{padding: 15, fontSize: 11, textAlign: 'left'}}>{attributes?.map(att => {
                                        //     return (
                                        //         <div >
                                        //            <span>{att.trait_type} : </span> {att.value}
                                        //             {/* {traitValueHandler(att.trait_type)} */}
                                        //         </div>
                                        //     )
                                        // })}</div> : ''
                                    }
                                    
                                    {/* <FileSearchOutlined
                                        onClick={() =>
                                            window.open(
                                                `${getExplorer(chainId)}address/${nft.token_address
                                                }`,
                                                "_blank",
                                            )
                                        }
                                    />
                                    <SendOutlined onClick={() => handleTransferClick(nft)} /> */}
                                </TeamCard>
                            );
                        })}
                </Skeleton>
            </div>
            <Modal
                title={`Transfer ${nftToSend?.name || "NFT"}`}
                show={visible}
                onHide={() => setVisibility(false)}
                confirmLoading={isPending}
                okText="Send"
            >
                <AddressInput autoFocus placeholder="Receiver" onChange={setReceiver} />
                {nftToSend && nftToSend.contract_type === "erc1155" && (
                    <Input
                        placeholder="amount to send"
                        onChange={(e) => handleChange(e)}
                    />
                )}
                <Button onClick={() => transfer(nftToSend, amountToSend, receiverToSend)}>Send</Button>
            </Modal>
        </div>
    );
}

export default NFTBalance;
