import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
import GifArea from './sections/GifArea';



const Application = () => {

    return (
      <div style={{height: '100vh', textAlign: 'center', paddingTop: '5%'}}>
        <h3>PET FRENS</h3>
        <p><span style={{fontWeight: 'bold'}}>contract address:</span> <a target="_blank" href='https://bscscan.com/address/0x3Cf2F014A7124C40A78eB222d0138A39ABC829D9'>0x3Cf2F014A7124C40A78eB222d0138A39ABC829D9</a> </p>
        <p><span style={{fontWeight: 'bold'}}>nftkeyapp</span> <a target="_blank" href='https://nftkey.app/collections/petfrens/'>🔗 link</a> </p>
         <a href='https://linktr.ee/petfrens'>#frenswillbefrens</a> 
      <div style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
        <GifArea />
      </div></div>
    );
  
};
ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
