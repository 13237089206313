import React from 'react'
import { Container, Row } from 'react-bootstrap'
import HorizontalLine from './components/HorizontalLine'
import WhyBeAFren from './sections/WhyBeAFren'
import Team from './sections/Team'
import Roadmap from './sections/Roadmap'
import GifArea from './sections/GifArea'
import PetFrens from './sections/PetFrens'
import MintComponent from './sections/MintComponent'
import styled from 'styled-components'
import FAQ from './sections/FAQ'

const OnePage = () => {
  return (
    <div>
      <Container>
        <StyledRow>
          {/* <MintComponent /> */}
          <GifArea />
        </StyledRow>
      </Container>

      <PetFrens />
      <Row>
        <Roadmap />
        <WhyBeAFren />
        <HorizontalLine width='100%' />
      </Row>
      <Team />
      <HorizontalLine width='100%' />
      <FAQ />
    </div>
  )
}

const StyledRow = styled(Row)`
margin-top: 10px;
@media (max-width: 585px) {
  padding: 20px;
}
`

export default OnePage