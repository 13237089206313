import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "../../helpers/formatters";
import Blockie from "../Blockie/Blockie";
import { Button, Card } from "antd";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Address from "../Address/Address";
import { SelectOutlined } from "@ant-design/icons";
import { getExplorer } from "../../helpers/networks";
import Text from "antd/lib/typography/Text";
import { connectors } from "./config";
import styled from "styled-components";
// import { Button as Buttonn } from '../../components'
// import { colors } from "../../constants/color";

const StyledButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
box-shadow: -3px 2px 0px 0px #000000;


`
const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: "fit-content",
    borderRadius: "12px",
    // backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: 'black',
  },
  connector: {
    alignItems: "center",
    display: "flex",
    // flexDirection: "column",
    // width: '100%',
    height: "auto",
    justifyContent: "center",
    // marginLeft: "auto",
    // marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
    border: '2px solid black',
    borderRadius: '4px',
    margin: 10
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    marginRight: "8px",
    height: "30px",
  },
};

function Account() {
  const { authenticate, isAuthenticated, account, chainId, logout } =
    useMoralis();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);

  if (!isAuthenticated || !account) {
    return (
      <>
        <StyledButton onClick={() => { setIsAuthModalVisible(true) }}>
          Connect Wallet
        </StyledButton>
        {/* <div onClick={() => {setIsAuthModalVisible(true) ;console.log('TEST')}}>
          <p style={styles.text}>Authenticate</p>
        </div> */}
        <Modal
          style={{ top: '20%' }}
          show={isAuthModalVisible}
          //   footer={null}
          onHide={() => setIsAuthModalVisible(false)}
        //   bodyStyle={{
        //     padding: "15px",
        //     fontSize: "17px",
        //     fontWeight: "500",
        //   }}
        //   style={{ fontSize: "16px", fontWeight: "500" }}
        //   width="340px"
        >
          <div
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "700",
              fontSize: "20px",
              backgroundColor: '#212529',
              color: 'white'
            }}
          >
            Connect Wallet
          </div>
          <div style={{ display: "grid", background: '#ffe14b' }}>
            {/* <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}> */}
            {connectors.map(({ title, icon, connectorId }, key) => (
              <div
                style={styles.connector}
                key={key}
                onClick={async () => {
                  try {
                    await authenticate({ provider: connectorId });
                    window.localStorage.setItem("connectorId", connectorId);
                    setIsAuthModalVisible(false);
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <img src={icon} alt={title} style={styles.icon} />
                <Text style={{ fontSize: "14px" }}>{title}</Text>
              </div>
            ))}
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      {/* <button
        onClick={async () => {
          try {
            console.log("change")
            await web3._provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            });
            console.log("changed")
          } catch (e) {
            console.error(e);
          }
        }}
      >
        Hi
      </button> */}
      <div style={styles.account} onClick={() => setIsModalVisible(true)}>
        <p style={{ marginRight: "5px", ...styles.text, marginBottom: 0 }}>
          {getEllipsisTxt(account, 4)}
        </p>
        <Blockie currentWallet scale={3} />
        <br />
      </div>
      <Modal
        show={isModalVisible}
        // footer={null}
        style={{
          top: '20%',
        }}
        onHide={() => setIsModalVisible(false)}
      // bodyStyle={{
      //   padding: "15px",
      //   fontSize: "17px",
      //   fontWeight: "500",
      // }}
      // style={{ fontSize: "16px", fontWeight: "500" }}
      // width="400px"
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            fontWeight: "700",
            fontSize: "20px",
            backgroundColor: 'black',
            color: 'white',
          }}
        >
          Account
        </div>
        <Card
          style={{
            backgroundColor:'#ffe14b'
 

          }}
          bodyStyle={{
            padding: "15px",
          }}
        >
          <Address
            avatar="left"
            size={6}
            copyable
            style={{ fontSize: "15px" }}
          />
          <div style={{ marginTop: "10px", padding: "0 10px" }}>
            <a
              href={`${getExplorer(chainId)}/address/${account}`}
              target="_blank"
              rel="noreferrer"
            >
              <SelectOutlined style={{ marginRight: "5px" }} />
              View on Explorer
            </a>
          </div>


          <div style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
          <StyledButton
          style={{
            fontSize: 15

          }}
          onClick={async () => {
            await logout();
            window.localStorage.removeItem("connectorId");
            setIsModalVisible(false);
          }}
        >
          Disconnect Wallet
        </StyledButton>
          </div>
        </Card>
        
      </Modal>
    </>
  );
}

export default Account;
