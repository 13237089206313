import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { Table } from 'antd'
import { SH2 } from './components/titles'
import Web3 from "web3";

const data = require('./data.json')

const StyledInput = styled.input`
    padding: 5px;
    width: 350px;
    margin-bottom: 20px;
`

const StyledDiv = styled.div`
display: flex;
justify-content: left;
flex-direction: column;
text-align: center;
border: 2px black solid;
padding: 15px;
`

const Label = styled.span`
font-weight: 900;
font-size: 19px;
`



const RewardList = () => {

    const [dataList, setDataList] = useState(data)
    const [wallet, setWallet] = useState('')

    // const searchHandler = (e) => {
    //     console.log(e.targe)

// const odenen = [0x092863bddb6aff53e3dfccab97885ba7302fcb6d,
//     0x44b26127560577a49572020eb98d47c365167a65,
//     0x779357013550e61bcda605bd0d11cb292e0978e7,
//     0x65d6cb1fa7633fc221402d59b4ae838fc2419602,
//     0xda4d057d24327135ee73323a966c4ad3aac1b518,
//     0x8db21c3f4efb3d70cde3a9764bbd39d8c6bdf9fc,
//     0xf912555b4c5d6bf8b92812b6e243b492275738ab,
//     0x03ebac2d81a569a024c7d72b27aa25297184ac96,
//     0x3039df7971bd2a05968ca43f725defcf9b3f5550,
//     0x481fe4b0a413ddac0e0e19f1a7c416fe28af670e,
//     0x65b9145441233ab52aea9865df87f351812dd1df,
//     0x9f5b79f19f40168564bd1e42df369a136fecf2e7,
//     0xa42fc3ee219984315e2b3d71ba9e157baf5cd24c,
//     0xed0eccddf45fe869e06dfe89b995bea54168bf9d,
//     0xbf67727e3ab131999db19567a235f64ba3f32ebc,
//     0x02e8529af60faede58211b0c1b448ec8992628ea,
//     0x472eb4f87e4fed1d85a502e999a413144d041daa,
//     0x5d33ca97efc658000335260a2e2ee0dd2986f85b,
//     0x879f73da71721ab76e82169d5b40697bb1e8263d,
//     0xab77380ff5826d416b7ac41b91978a27604b2156,
//     0xb9cf399ba8ad7e3980b0c46e83620b2f3b4eaf9e,
//     0xc6aa674b503ff5f95f08a2b9d3e5cf39882e9309]
//     useEffect(() => {
//         let total = ''
//         for (let index = 0; index < dataList.length; index++) {
//             const element = dataList[index];
//             if (element.Rank >=180) {
//                 total += `${element.Address},`
//             }

//         }
//         console.log('total', total);
//     }, [])


    // }
    // console.log(Web3.utils.toChecksumAddress(data[0].Address))
    // let toplam = 0
    // let yuzdeT = 0
    // data.forEach(d => {
    //     if ( Number(d.Percentage.substring(0, (d.Percentage.length - 1)))*100 < 9) {
    //         toplam += 1
    //         yuzdeT += Number(d.Percentage.substring(0, (d.Percentage.length - 1)))*100
    //     }
    // toplam += Number(d.Percentage.substring(0, (d.Percentage.length - 1))) * 100
    //         console.log(`payable(${Web3.utils.toChecksumAddress(d.Address)}).transfer(
    //     (balance * ${Number(d.Percentage.substring(0, (d.Percentage.length - 1))) * 100}) / 10000
    // );`)
    // })
    //     console.log('TOPLAm',toplam)
    //     console.log('yuzdet',yuzdeT)

    return (
        <Container>
            <SH2>RewardList</SH2>
            {/* <Row>
                <Col md={7}>
                    <StyledDiv>
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            <Label>Wallet Addres:</Label>
                            <StyledInput
                             style={{marginLeft: 17, marginRight: 17}}
                              onInput={(e) => { console.log(e.target.value); setWallet(e.target.value)  }} 
                            placeholder='Enter Your Address Calculate Reward' />
                            <>Total Amount : 
                        8.1546 BNB</>
                        </div>
                        <div  style={{ display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                            8.1546 BNB * [your_percentage] = {
                             wallet  ? console.log( dataList.find(d => d.Address === wallet).Percentage) : ''
                            }
                        </div>
                        

                    </StyledDiv>
                </Col>
                <Col>
                </Col>
            </Row> */}

            {/* <div style={{ marginBottom: 10 }}>
                <div style={{ textAlign: 'left' }}><span style={{ color: 'red' }}>Calculation : </span><span style={{ color: 'purple' }}>(pet_frens_revenue)</span>  * <span style={{ color: 'green' }}> (your_percentage)</span></div>
                <div style={{ textAlign: 'left' }}><span style={{ color: 'red' }}>Example : </span> If you are Rank 1 holder and Pet Frens sold out. ( Price per Pet: 0.035BNB ). <br /> <span style={{ color: 'purple', marginLeft: 72 }}>(6667 * 0.035) </span> *<span style={{ color: 'green' }}> (5.3330 / 100)</span> = <span style={{ border: '1px solid black', padding: 2, fontSize: 18, backgroundColor: 'black', color: 'gold' }}> 12.4442 BNB</span> </div>
            </div> */}
 <StyledInput onChange={(e) => { setDataList(data.filter(member => member.Address.toLowerCase().includes(e.target.value.toLowerCase()))) }} placeholder='Search Address 🔍' />
 <p>If your revenue share are less than 0.035 bnb, we will give you 🎁 1x pet fren.</p>
            <table style={{ border: '2px solid black' }} width={'100%'}>
                <tr >
                    <th>Rank</th>
                    <th>Address <div style={{ textAlign: '' }}>
                       
                    </div></th>
                    <th>Percentage</th>
                    <th>Quantity</th>
                    <th>Reward</th>
                </tr>
                {dataList.map(member => {
                    return (
                        <tr style={{borderBottom: '1px solid'}}>
                            <td>{member.Rank} ✅</td>
                            <td>{member.Address.substring(0, 5)}...{member.Address.substring(member.Address.length-4, member.Address.length-1)}</td>
                            <td>{(Number(member.Percentage.substring(0, (member.Percentage.length - 1)))).toFixed(4)} ﹪</td>
                            <td>{member.Quantity}</td>
                            <td>{member.Rank <= 55 ? `${((Number(member.Percentage.substring(0, (member.Percentage.length - 1)))) * 8.15 / 100).toFixed(4)} BNB` : `1x Pet Fren` }</td>
                        </tr>
                        
                    )
                    
                })}
<tr style={{borderBottom: '1px solid'}}>
                            <td>TOTAL</td>
                            <td></td>
                            <td>100 ﹪</td>
                            <td>3333</td>
                            <td>8.15 BNB
                       </td>
                        </tr>
            </table>

        </Container>
    )
}

export default RewardList