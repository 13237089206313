import '../App.css';
import { Navbar, Nav } from 'react-bootstrap'
import logopet from '../assets/logo_petfrens.png'
import Account from '../Web3/Account/Account'
import styled from 'styled-components'
import HorizontalLine from '../components/HorizontalLine'
import { FaTelegram, FaTwitter, FaMedium } from 'react-icons/fa';


const TopArea = () => {
    return (
        <>
            <div style={{ display: 'flex', justifySelf: 'center', justifyContent: 'space-between', width: '90%', marginLeft: '5%' }}>
                <SocialMedia>
                    <a style={{color: 'black'}} href='https://t.me/catfrens' target={'_blank'}><FaTelegram style={{ fontSize: 22, marginRight: 10 }} /></a>
                    <a style={{color: 'black'}} href='https://twitter.com/CatFrensBSC' target={'_blank'}><FaTwitter style={{ fontSize: 22,marginRight: 10 }} /></a>
                    <a style={{color: 'black'}} href='https://medium.com/@catfrens' target={'_blank'}><FaMedium style={{ fontSize: 22 }} /></a>
                    
                </SocialMedia>
                <Logo src={logopet} />
                <AccountWrapper>
                    <Account />
                </AccountWrapper>
            </div>
            <HorizontalLine />
            <Navbar expand="lg" style={{ fontSize: 32, justifyContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledSpan>🚨 0.001BNB @ 08.05.22</StyledSpan>
                        <Navbar.Toggle style={{ marginLeft: 20 }} aria-controls="basic-navbar-nav" /></div>
                    <Navbar.Collapse style={{fontSize: 24}} id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link style={{ color: window.location.pathname === '/' || '' ? 'black' : '' }} href="/">Home</Nav.Link>
                            <Nav.Link style={{ color: window.location.pathname === '/mynfts' ? 'black' : '' }} href="/mynfts">My Pets</Nav.Link>
                            <Nav.Link style={{ color: window.location.pathname === '/traits' ? 'black' : '' }} href="/traits">Traits</Nav.Link>
                            <Nav.Link style={{ color: window.location.pathname === '/rewards' ? 'black' : '' }} href="/rewards">Rewards🗒</Nav.Link>
                            <Nav.Link href="/#roadmap">Roadmap</Nav.Link>
                            <Nav.Link href="/#team">Team</Nav.Link>
                            <Nav.Link href="/#faq">FAQ</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
            <HorizontalLine /></>
    )
}

const StyledSpan = styled.span`
font-weight: 600;
color: white;
background-color: black;
padding: 5px;
border-radius: 4px;
font-size: 22px;
@media (min-width: 991px) {
  display: none;
}
`

const SocialMedia = styled.div`
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 585px) {
  display: none;
}
`

const AccountWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
font-size: 22px;
font-weight: 600;
`
const Logo = styled.img`
width: 300px;
@media (max-width: 585px) {
 width: 55%;
}
@media (min-width: 748px) {
  margin-left: 90px;
}
`

export default TopArea