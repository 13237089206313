import React from 'react'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'

import birdGif from '../assets/birdNew.gif'
import monkeyGif from '../assets/monkeyNew.gif'
import dogGif from '../assets/dogNew.gif'
import bunnyGif from '../assets/bunnyNew.gif'


const GifArea = () => {
  return (
    <Col xs={12} lg={4}>
            <Row style={{ padding: 5 }}>
              {gifs.left.map(gif => <Col lg={6} xs={6}>
                <StyledGif src={gif} /></Col>)}
            </Row>
            <Row style={{ padding: 5 }}>
              {gifs.right.map(gif => <Col lg={6} xs={6}>
                <StyledGif src={gif} /></Col>)}
            </Row>
          </Col>
  )
}

const StyledGif = styled.img`
border: 2px solid black;
width: 200px;
@media (max-width: 585px) {
  width: 150px;
}
`
const gifs = {
  left: [birdGif, bunnyGif],
  right: [dogGif, monkeyGif]
}

export default GifArea