import './App.css';
import { useMoralis, useNativeBalance,useMoralisWeb3Api } from "react-moralis";
import Web3 from "web3";
import { useState, useEffect } from 'react'
import { Navbar, NavDropdown, Nav, Container, Row, Col } from 'react-bootstrap'
import logopet from './assets/logo_petfrens.png'

import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import OnePage from './OnePage'
import NFTBalance from './Web3/NFTBalance/NFTBalance'

import Account from './Web3/Account/Account'
import styled from 'styled-components'
import HorizontalLine from './components/HorizontalLine'
import useWindowDimensions from './utils/getWindowSize'

import { FaTelegram, FaTwitter } from 'react-icons/fa';
import TopArea from './sections/TopArea';
import Traits from './Traits';
import RewardList from './RewardList';



function App() {
  // const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
  //   useMoralis();
  // const [totalSupply, setTotalSupply] = useState(0)


  

  // const web3 = new Web3('https://speedy-nodes-nyc.moralis.io/494cdd02153c39323af9827a/bsc/mainnet')

  // useEffect(() => {
  //   const connectorId = window.localStorage.getItem("connectorId");
  //   if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading)
  //     enableWeb3({ provider: connectorId });

  // }, [isAuthenticated, isWeb3Enabled]);

  return (
    <div className="App" style={{ paddingTop: 20 }}>
aswdawdaw

      <HorizontalLine width='100%' />

<a style={{color: 'black'}} href='https://twitter.com/hashtag/frenswillbefrens?src=hashtag_click' target={"_blank"}>#frenswillbefrens</a>
    </div>
  );
}


export default App;
