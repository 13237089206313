import React from 'react'
import { Container, Accordion,Row,Col } from 'react-bootstrap'
import styled from 'styled-components'
import HorizontalLine from '../components/HorizontalLine'
import sayHi from '../assets/say_hi_petfrens.png'
import { SH1 } from '../components/titles'

export default function FAQ() {
    return (
        <Row id='faq'>
            <Col style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end', borderRight: '2px solid black'}} lg={6} xs={12}>
            {/* <div>FREQUENTLY
ASKED QUESTIONS</div> */}
<div> <img width={400} src={sayHi} /></div>
               
                
            
            </Col>
            <Col lg={6} xs={12}>
            <Container fluid>
                <SH1 style={{textAlign: 'left', padding: 20}}>FAQ</SH1>
            <StyledAcc defaultActiveKey="0">
                {faqs.map((f, i) => (
                    <Accordion.Item eventKey={i}>
                        <Accordion.Header>{f.title}</Accordion.Header>
                        <Accordion.Body>
                            {f.text} {f.link && f.link}
                        </Accordion.Body>
                        {faqs.length !== i+1 ? <HorizontalLine width='100%' /> : ''}
                        
                    </Accordion.Item>
                ))}
            </StyledAcc>
        </Container></Col>
        </Row>
    )
}

const StyledAcc = styled(Accordion)`
/* background-color: #e5e5e5; */
padding: 10px;
    .accordion-button{
        font-size: 22px;
        font-weight: 600;
        background-color: #ffe14b;
        &:after {
        background-image: none;
        content: "+";
        }
    }
    .accordion-item {
        border: none;
        background-color: #ffe14b;
        text-align: left;
    }
    .accordion-button {
        &:focus {
        color: black;
        border: none;
        z-index: 3;
        outline: 0;
        box-shadow: none;
     }
  }
  &:not(.collapsed) {
      color: black
  } 
`

const faqs = [
    {
        title: 'What is the total supply and price of Pet Frens?',
        text: 'There are 6666 Pet Frens in total, all with a mint price of 0.035 BNB. 166 Pet Frens will be set aside for giveaways.',
        color: 'dark'
    },
    {
        title: 'How and when the revenue of the Pet Frens be distributed?',
        text: '50% of the revenue from the 6666 Pet Frens NFTs ( The snapshot will be taken on 05.05.22 24:00 UTC to determine the Pet Frens shareholders.) will be shared directly with the 3333 Cat Frens holders equally after the minting. More surprises coming for the Pet Frens holders. 👀',
        color: 'dark'
    },
    {
        title: 'How and when the revenue of the second collection Pet Frens be distributed?',
        text: 'After the minting of the Pet Frens(Launching on April), 50% of the revenue will be distributed directly to the holders of the Cat Frens equally. The snapshot time and rules will be announced later. ',
        color: 'dark'
    },
    {
        title: 'When can I begin minting?',
        text: 'You can begin minting Pet Frens NFTs starting on May 8th, 2022.',
        color: 'dark'
    },
    {
        title: 'Where can I trade my Pets?',
        text: 'We are communicating with the biggest marketplaces in the BSC ecosystem such as nftkey.app, PancakeSwap and TofuNFT where you’ll be to list and trade your cats.',
        color: 'dark'
    },
    {
        title: 'If I have further questions, where can I ask? ',
        text: 'Feel free to join us on our telegram, or twitter! We’re always open to hear your feedbacks and questions :)',
        color: 'dark'
    },
    


]
