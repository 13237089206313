
import styled from 'styled-components'
export const SH1 = styled.h1`
font-weight: 700;
`

export const SH2 = styled.h2`
font-weight: 600;
`

const StyledP = styled.p`
font-size: 18px;
`
export const SButton = styled.button`
  background-color: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
box-shadow: -3px 2px 0px 0px #000000;
`

export const P = ({textAlign, children}) => <StyledP style={{textAlign: textAlign}}>{children}</StyledP>